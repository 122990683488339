





































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {CalendarEvent, DataTableHeader} from "vuetify";
import SymptomDialog from "@/components/calendar/SymptomDialog.vue";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import Webservice from "@/assets/service/Webservice";
import {GenericWrapper} from "@/assets/Entities/GenericEntity";
import User, {GuestCalendarList, Question, QuestionStatus} from "@/assets/Entities/User";
import SchoolClass from "@/assets/Entities/SchoolClass";
import pdf from "vue-pdf";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import {Action} from "@/assets/service/Endpoints";

@Component({
  components: {
    SymptomDialog,
    pdf,
    vueQr
  }
})
export default class Calendar extends Vue {
  selectedMonth: number = (new Date()).getMonth();
  selectedYear: number = (new Date()).getFullYear();
  selectedSchool: string = '';
  selectedClass: string = '';
  totalItems: number = 0;
  firstDate: Date = new Date(0);
  now: Date = new Date();
  items: User[] = [];
  options = {};
  guests: boolean = false;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'];

  loading: boolean = false;
  guestList: GuestCalendarList[][] = [];
  selectedEvent: any = {};
  selectedOpen: boolean = false;
  selectedElement: any = null;

  imgSource: string = '';
  ext: string = '';
  token: string = '';

  created() {
    Webservice.performRequest<GenericWrapper<SchoolClass>>(RequestType.GET,
        {
          type: TargetType.DEPARTMENT,
          client: this.$route.params.client
        },
        {},
        val => {
          if ((val as GenericWrapper<SchoolClass>).items) {
            this.$store.commit('SET_AVAILABLE_CLASSES', (val as GenericWrapper<SchoolClass>).items);
          }
        })
  }

  get events() {
    const events: CalendarEvent[] = [];

    for (const index in this.guestList) {
      this.guestList[index].forEach(value1 => {
        events.push({
          name: value1.name,
          start: index,
          end: index,
          timed: false,
          color: 'blue',
          id: value1.id,
          company: value1.company
        })
      })
    }

    return events
  }

  get isAdmin(): boolean {
    return this.$store.getters.currentUser.role === UserType.Admin;
  }

  showEvent({nativeEvent, event}) {
    const open = () => {
      this.selectedEvent = event
      this.selectedElement = nativeEvent.target
      requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
    }

    if (this.selectedOpen) {
      this.selectedOpen = false
      requestAnimationFrame(() => requestAnimationFrame(() => open()))
    } else {
      open()
    }

    nativeEvent.stopPropagation()
  }

  getSymptomsForDate(item: User, day: number): Question | undefined {
    return item.daily?.filter(value => {
      if (value.date !== undefined) {
        const date = new Date(value.date);
        return (date.getFullYear() === this.selectedYear
            && date.getMonth() === this.selectedMonth
            && date.getDate() === day)
      }
    })[0];
  }

  getBackgroundColorForDate(item: User, day: number): string {
    let question: Question | undefined = this.getSymptomsForDate(item, day);
    let currDay = new Date(this.selectedYear, this.selectedMonth, day);
    if (question && question.status === undefined) {
      switch (question.type) {
        case 'vacation':
          return 'background-color: purple';
        case 'illness':
          return 'background-color: yellow';
        case 'test':
          return 'background-color: green';
        case 'homeoffice':
          return 'background-color: blue';
        case 'other':
          return 'background-color: lightblue';
      }

      return 'background-color: red';
    }
    if (item.immun) {
      let immun = new Date(item.immun);
      if (currDay.valueOf() <= immun.valueOf()) {
        return 'background-color: green';
      }
    }
    switch (question?.status) {
      case QuestionStatus.GoodToGo:
        return 'background-color: green';
      case QuestionStatus.ShouldCheck:
        return 'background-color: yellow';
      case QuestionStatus.Quarantine:
        return 'background-color: red';
      default:
        if (Date.now().valueOf() >= currDay.valueOf()) {
          return 'background-color: red';
        } else {
          return '';
        }
    }
  }

  get availableClasses(): SchoolClass[] {
    return this.$store.getters.availableClasses;
  }

  get header(): DataTableHeader[] {
    let header: DataTableHeader[] = [{
      text: 'Login',
      value: 'login',
      width: '100px',
      divider: true
    }];

    const date = new Date(this.selectedYear, this.selectedMonth + 1, 0);

    for (let i = 1; i <= date.getDate(); i++) {
      header.push({
        text: i.toString(),
        value: '',
        sortable: false,
        width: '30px',
        divider: true,
        align: 'center',
        class: 'slim-column'
      })
    }

    return header;
  }

  changeMonth(val: number) {
    this.selectedMonth += val;
    if (this.selectedMonth > 11) {
      this.selectedYear += 1;
      this.selectedMonth = 0;
    }
    if (this.selectedMonth < 0) {
      this.selectedYear -= 1;
      this.selectedMonth = 11;
    }
  }

  disableTab(tab: number): boolean {
    let currDay = new Date(this.selectedYear, tab, 1);
    return Date.now().valueOf() <= currDay.valueOf();
  }

  toggleCombobox(cbx: string) {
    const combobox: any = this.$refs[cbx];
    if (combobox.isMenuActive) {
      (this.$refs[cbx] as any).isMenuActive = false;
    } else {
      (this.$refs[cbx] as any).isMenuActive = true;
      combobox.focus();
    }
  }

  @Watch('guests')
  @Watch('selectedClass')
  @Watch('selectedMonth')
  @Watch('selectedYear')
  @Watch('options', {deep: true})
  loadData() {
    this.loading = true;
    let param = {};
    param['itemsPerPage'] = this.options['itemsPerPage'];
    param['page'] = this.options['page'];
    param['date_start'] = this.$d(new Date(this.selectedYear, this.selectedMonth, 1));
    param['date_end'] = this.$d(new Date(this.selectedYear, (this.selectedMonth + 1), 0));

    Webservice.performRequest<QuestionWrapper | GuestCalendarList[]>(RequestType.POST,
        {
          type: this.guests ? TargetType.GUEST_DATES : TargetType.DEPARTMENT,
          client: this.$route.params.client,
          departmentId: this.guests ? undefined : this.selectedClass,
          action: Action.DAILY
        },
        param,
        val => {
          if (this.guests) {
            this.guestList = (val as GuestCalendarList[][]);
          } else {
            this.items = (val as QuestionWrapper).items;
            // this.firstDate = new Date((val as QuestionWrapper).firstDate);
            this.totalItems = (val as QuestionWrapper).count;
          }
          // this.$store.commit('SET_AVAILABLE_QUESTIONS_LIST', (val as Wrapper<Question>).items);
        }, error => {
        }, () => this.loading = false);
  }

  @Watch('selectedOpen')
  loadImages() {
    this.token = '';
    this.imgSource = '';
    if (this.selectedOpen) {
      Webservice.performRequest<{ signedUrl: string, token: string }>(RequestType.GET, {
        type: TargetType.GUEST_UPLOADS,
        client: this.$route.params.client,
        guestId: this.selectedEvent.id
      }, {}, val => {
        if (typeof val !== 'string' && val) {
          if (val.signedUrl) {
            this.ext = val['content-type']?.replace('application/', '');
            this.imgSource = val.signedUrl
          } else {
            this.token = val.token;
          }
        }
      })
    }
  }
}

class QuestionWrapper {
  items!: User[];
  firstDate!: Date | string;
  count!: number;
}
