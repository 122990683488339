


















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Question} from "@/assets/Entities/User";
import {RequestType, TargetType} from "@/assets/constants/Enums";
import pdf from "vue-pdf";
import Webservice from "@/assets/service/Webservice";

@Component({
  components: {pdf}
})
export default class SymptomDialog extends Vue {
  contacts: string[] = [];
  locations: string[] = [];

  dialogOpen: boolean = false;

  testImage: string = '';
  ext: string = '';

  @Watch('dialogOpen', {deep: true})
  setImageURL() {
    if (this.symptoms?.img && this.dialogOpen) {
      Webservice.performRequest<{ signedUrl: string }>(RequestType.GET, {
        type: this.symptoms.type === 'test' ? TargetType.TEST : TargetType.CERT,
        certId: this.symptoms.img,
        client: this.$route.params.client,
        userId: this.userId
      }, {}, val => {
        if (val && typeof val !== 'string') {
          this.ext = val['content-type']?.replace('application/', '');
          this.testImage = val.signedUrl;
        }
      });
      return;
    }
    this.testImage = '';
  }

  @Prop() userName!: string;
  @Prop() date!: Date;
  @Prop() symptoms!: Question;
  @Prop() schoolId!: string;
  @Prop() userId!: string;
}
